import { observer } from 'mobx-react';
import { useContext } from 'react';

import { CaseStatusList, __ } from '@adac/core-model';
import {
  ChildrenProps,
  ErrorMessage,
  View,
  matchStatus,
} from '@adac/core-view';
import StoreContext from '../../stores';

interface HubbleCommissionFeedbackRetryProps extends ChildrenProps {}

export const HubbleCommissionFeedbackRetry = observer(
  ({ children }: HubbleCommissionFeedbackRetryProps) => {
    const stores = useContext(StoreContext);
    if (
      matchStatus(
        [
          CaseStatusList.DRIVER_SELECTED,
          CaseStatusList.SCHEDULED_DRIVER_SELECTED,
        ],
        stores.case.status
      )
    ) {
      return (
        <>
          <ErrorMessage fullWidth border='solid 1px green'>
            <View fullWidth border='solid 1px green'>
              {__(
                'Commission accept response was registered into OpenHome, ADAC confirmation failed, Company can force retry'
              )}
            </View>
          </ErrorMessage>
          {children}
        </>
      );
    }
    return null;
  }
);
