import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import 'moment/locale/de';

import { initSentry, validateConfig } from '@adac/core-view';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

initSentry('SND_DRIVER');
validateConfig('SND_DRIVER')(process.env);

moment.locale(process.env.REACT_APP_LOCALE || 'de');

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
