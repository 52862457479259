import { BillingAddressProps, __, getFullName } from '@adac/core-model';
import {
  AddressForm,
  Checkbox,
  CheckboxItem,
  InfoItem,
  InputLayout,
  NameForm,
  SmallText,
  createAddressString,
  getBillingCoordsByAddress,
  isBillingAddressReady,
  useView,
} from '@adac/core-view';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { FlexView, Icon, View } from '../ui';
import { useBillingAddressFormValues } from '../../hooks/useBillingAddressFormValues';
import { FinalReportFormValues } from '../pages/FinalReport';

interface BillingAddressStylesProps {
  isValid: boolean;
}

const BillingAddressWarning = styled(FlexView)``;
const BillingAddressStyles = styled(View)<BillingAddressStylesProps>`
  & > #form {
    ${(props) =>
      !props.isValid &&
      `
      border: 2px solid red;
      border-radius: 12px;
      padding: 0px 8px 8px;
      margin: 8px -8px;
    `}
  }
  & > #warning {
    * {
      ${(props) => (!props.isValid ? 'display: inline-block' : 'display: none')}
    }
    & > p {
      color: red;
      font-weight: bold;
      margin-left: 5px;
    }
  }
`;

interface FinalFormBillingAddressProps {
  billingAddressFromServer: BillingAddressProps | null | undefined;
  addressString: string;
}

const FinalFormBillingAddress = ({
  billingAddressFromServer,
  addressString,
}: FinalFormBillingAddressProps) => {
  const { isView } = useView();
  const {
    setFieldValue,
    values: { showAddress },
  } = useFormikContext<FinalReportFormValues>();
  const { differentBillingAddress, isBillingAddressValid } =
    useBillingAddressFormValues(billingAddressFromServer);
  const showBillingAddressText =
    (!showAddress && isBillingAddressValid) || isView;

  return (
    <>
      {!isView && (
        <Checkbox
          id='showAddress'
          labelTitle={__('Enter different billing address')}
          checked={showAddress}
          value='showAddress'
          onChange={() =>
            isBillingAddressValid && setFieldValue('showAddress', !showAddress)
          }
          render={CheckboxItem}
          disabled={isView}
        />
      )}

      {showAddress && (
        <BillingAddressStyles isValid={isBillingAddressValid}>
          <BillingAddressWarning id='warning'>
            <Icon name='alert' color='red' size='15px' />
            <SmallText>{__('BillingAddressWarning')}</SmallText>
          </BillingAddressWarning>

          <InputLayout>
            <AddressForm
              showLocationBtn={false}
              showEditButton={false}
              isAddressReady={isBillingAddressReady}
              getCoordsByAddress={getBillingCoordsByAddress}
            />
            <NameForm />
          </InputLayout>
        </BillingAddressStyles>
      )}

      {showBillingAddressText && (
        <InfoItem
          header={__('Billing name')}
          info={getFullName(differentBillingAddress)}
        />
      )}
      {showBillingAddressText &&
        addressString !== createAddressString(differentBillingAddress) && (
          <InfoItem
            header={__('Billing address')}
            info={createAddressString(differentBillingAddress)}
          />
        )}
    </>
  );
};
export default FinalFormBillingAddress;
