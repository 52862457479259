import { CaseDTO, DriverActionType, __, getApiRoutes } from '@adac/core-model';
import { useContext } from 'react';

import { PostStore, request } from '@adac/core-view';
import StoreContext from '../stores';

function assertCompanyId(id: number | undefined): asserts id is number {
  if (!id) {
    throw new Error(__('Company cannot be identified'));
  }
}

export function useWorkflowDriverAction<Data>(caseAction: DriverActionType) {
  const { case: caseStore } = useContext(StoreContext);

  // TODO: DATA should be good to get derived from caseAction
  return async (data: Data) => {
    const companyId = caseStore.currentCompanyId;

    assertCompanyId(companyId);

    const driverAction = async () =>
      request<CaseDTO, Data>(
        getApiRoutes()
          .partner.case(caseStore.token)
          .action.setStatus(caseAction, companyId),
        {
          method: 'POST',
          data,
        }
      );
    const sndActionStore = new PostStore(driverAction);
    const item = await sndActionStore.postItem(caseStore.token, data);
    return caseStore.handleStoreAction(sndActionStore, item);
  };
}
