import styled from 'styled-components';

import { StaticMenuList, View, StaticMenuListStyles } from '@adac/core-view';
import { links } from '../../content/index';

const NavBarStyle = styled(View)`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;

  ${StaticMenuListStyles} {
    flex: 0 0 auto;
  }
`;

export default function NavBarContainer() {
  return (
    <NavBarStyle padding>
      <StaticMenuList items={links.nav} />
    </NavBarStyle>
  );
}
