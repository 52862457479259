import { useContext } from 'react';

import { ProductTypes } from '@adac/core-model';
import StoresContext from '../stores';

export function useIsInsuranceProduct() {
  const { case: caseStore } = useContext(StoresContext);

  return caseStore.productType === ProductTypes.INSURANCE;
}
