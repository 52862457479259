import { useContext } from 'react';
import { observer } from 'mobx-react';
import { ThemeProvider } from 'styled-components';
import { Layout, browser } from '@adac/core-view';

import StoreContext from '../../stores';
import MainPageContainer from './MainPageContainer';
import Header from './Header';
import OverlayContainer from './OverlayContainer';
import DevTools from '../dev/DevTools';
import OverlayErrorContainer from '../overlays/OverlayErrorContainer';
import FooterContainer from './Footer';
import { FinalFormBackButton } from '../composite/FinalFormBackButton';

export const LayoutContainer = observer(() => {
  const stores = useContext(StoreContext);

  return (
    <ThemeProvider theme={stores.ui.currentTheme}>
      <Layout nav={Header} browser={browser}>
        <MainPageContainer afterElement={<FooterContainer />} />
        <FinalFormBackButton />
        <OverlayContainer />
        <OverlayErrorContainer />
        <DevTools />
      </Layout>
    </ThemeProvider>
  );
});

export default LayoutContainer;
