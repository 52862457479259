import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import {
  CaseActions,
  __,
  getClaimReference,
  isValidMobilePhoneNumber,
} from '@adac/core-model';
import {
  AddressLink,
  BrowserLocationResult,
  ButtonListStyles,
  Divider,
  InfoItem,
  LightSubTitle,
  LocationButton,
  MapContainer,
  Page,
  ProductType,
  SubPage,
  Text,
  Timer,
  Title,
  UserInfo,
  extractDisplayLocationCoords,
  postDriverPosition,
  useAsyncComponent,
  useCustomerCoords,
  useSndDriverAddress,
} from '@adac/core-view';

import { useWorkflowCallCustomerAction } from '../../hooks/useWorkflowCallCustomerAction';
import { useWorkflowDriverAction } from '../../hooks/useWorkflowDriverAction';
import StoreContext from '../../stores';
import { AppointmentCountDown } from '../composite/Appointment';
import { AdditionalIncidentNotes } from './IncomingJobToDispatch';

export const LargeText = styled(Text)`
  font-size: ${(props) => props.theme.font.subTitle};
  margin-top: 8px;
`;

export const SubContent = styled(SubPage)`
  margin-top: 8px;
  margin-bottom: 8px;

  & > & {
    padding: 0px;
  }
`;

export const CommissioningReferenceText = styled(LightSubTitle)`
  display: 'inline-block';
  paddingleft: '12px';
`;

export const LightText = styled(Text)`
  color: ${(props) => props.theme.colors.gray};
`;

export const Line = styled(Divider)`
  margin: 16px auto;
`;

const IncomingDriverJob = observer(() => {
  const { case: caseStore } = React.useContext(StoreContext);
  const customerCoords = useCustomerCoords({ caseStore });
  const driverCoords = useSndDriverAddress({ caseStore });

  const {
    commissioningReference,
    currentCompanyId,
    token: caseToken,
    accessToken,
    customerPhone,
  } = caseStore;

  const driverArrivesAction = useWorkflowDriverAction(
    CaseActions.DRIVER_ARRIVES
  );
  const callCustomerAction = useWorkflowCallCustomerAction();

  const updateLocation = async (location: BrowserLocationResult) => {
    if (location.position) {
      const coords = extractDisplayLocationCoords(location.position.coords);
      try {
        if (currentCompanyId === undefined)
          throw new Error(`CompanyId is missing ${currentCompanyId}`);
        await postDriverPosition(
          { caseToken, accessToken },
          coords,
          currentCompanyId
        );
      } catch (error) {
        console.error(
          'An error occured while trying to post driver location to backend: ',
          error
        );
      }
    } else {
      console.error(
        'An error occured while trying to get driver location from browser: ',
        location.error
      );
    }
  };

  const { Component: CallCustomerButton } = useAsyncComponent({
    onClick: () =>
      callCustomerAction({
        companyId: currentCompanyId,
        driverId: caseStore.driverInfo?.driver?.id,
        number: customerPhone,
      }),
    enableAfterLoading: true,
  });

  const { Component: ArrivedButton } = useAsyncComponent({
    onClick: () => driverArrivesAction({}),
  });

  return (
    <Page>
      <SubContent>
        <Title>
          {__('Ihr')} {__('KeyEmergencyOrder')}:
          <CommissioningReferenceText>
            {getClaimReference(commissioningReference)}
          </CommissioningReferenceText>
        </Title>
        {caseStore.scheduledTs ? (
          <AppointmentCountDown />
        ) : (
          <React.Fragment>
            <LargeText>{__('Der Auftrag läuft seit')}</LargeText>
            <Timer startTime={caseStore.getDriverSelectedTime} />
          </React.Fragment>
        )}
      </SubContent>

      <MapContainer
        customerPosition={customerCoords}
        driverPosition={driverCoords}
        style={{ height: '160px' }}
      />

      <SubContent>
        <LocationButton onLocationChange={updateLocation} />
        <SubContent>
          <LightText>{__('RefreshLocation')}</LightText>
        </SubContent>

        <SubContent>
          <LightText>{__('DrivingPhone')}</LightText>
        </SubContent>

        <Line fullWidth />

        <ButtonListStyles>
          {isValidMobilePhoneNumber(caseStore.customerPhone) && (
            <CallCustomerButton
              link={`tel: ${caseStore.customerPhone}`}
              info
              title={__('Make a customer call')}
            />
          )}

          <ArrivedButton
            cta
            title={__('I have arrived at the target address')}
          />
        </ButtonListStyles>

        <InfoItem
          header={__('DoorComplexity')}
          info={__(`complexity:${caseStore.complexity}`)}
        />

        <ProductType productType={caseStore?.productType} />

        <UserInfo
          title={caseStore.title}
          firstName={caseStore.customerFirstName}
          lastName={caseStore.customerFamilyName}
          phone={caseStore.customerPhone}
          address={caseStore.customerFullAddressString}
        />

        {customerCoords !== null && <AddressLink {...customerCoords} />}

        <Line fullWidth />

        {caseStore.description !== '' && (
          <InfoItem
            header={__('Weitere Informationen')}
            info={caseStore.description}
          />
        )}
        {caseStore.facilityIssues && (
          <AdditionalIncidentNotes {...caseStore.facilityIssues} />
        )}
      </SubContent>
    </Page>
  );
});

export default IncomingDriverJob;
