import { useContext } from 'react';

import {
  isPositivePrice,
  useLocation,
  useAsyncComponent,
  useFormContext,
  CostItem,
} from '@adac/core-view';
import { CaseActions, __ } from '@adac/core-model';
import StoreContext from '../../../stores';
import { routeNames } from '../../layout/OverlayRouterContainer';
import { useWorkflowDriverAction } from '../../../hooks/useWorkflowDriverAction';

function formatRadioCosts(radioItems: CostItem[]) {
  const radioItem = radioItems?.find((item) => item.count > 0);
  if (radioItem) {
    const { calc, id, description, details, count, value } = radioItem;

    return {
      cost: calc({ value, count }),
      costItemId: id,
      description,
      details,
      count,
    };
  }
  return undefined;
}

function formatCost(cost: CostItem) {
  const { calc, id, description, count, value } = cost;
  return {
    cost: calc({ value, count }),
    costItemId: id,
    description,
    count,
  };
}

export function ConfirmExtraCostsButton() {
  const { case: caseStore } = useContext(StoreContext);
  const { goToWithRootId } = useLocation();
  const { isValid } = useFormContext();

  const extraCostAction = useWorkflowDriverAction(
    CaseActions.COMISSION_EXTRA_COST
  );

  const filterRadioItems = (radioItems: CostItem[]) =>
    radioItems.filter((item) => item.count);

  const saveAdditionalCosts = async () => {
    const additionalCosts = caseStore.extraCostItems
      .filter(
        (cost) =>
          cost.count || (cost.radioItems && filterRadioItems(cost.radioItems))
      )
      .map((cost) =>
        cost.radioItems ? formatRadioCosts(cost.radioItems) : formatCost(cost)
      )
      .filter((cost) => cost);

    await extraCostAction({ additionalCosts });
    if (!caseStore.hasError)
      goToWithRootId(routeNames.finalStatusReport('images'));
  };

  const containsNonPositivePrice = caseStore.extraCostItems.some(
    ({ count, value }) => !isPositivePrice(value) && count
  );

  const { Component: SubmitButton } = useAsyncComponent({
    onClick: saveAdditionalCosts,
    disabled: containsNonPositivePrice || !isValid,
  });

  return <SubmitButton cta title={__('Confirm')} />;
}
