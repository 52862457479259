import styled from 'styled-components';
import { View, Carousel, DoorPhotoStore, DoorPhoto } from '@adac/core-view';
import NewImage from './NewPhoto';
import { CarouselNavigatorButton } from './CarouselNavigatorButton';
import { ImageCarouselItem } from './ImageCarouselItem';

const CarouselStyles = styled(View)`
  width: 100%;
  margin: 16px 0;
`;

interface Props {
  doorStore: DoorPhotoStore;
  currentIndex?: number;
  onIndexChange: (index: number) => void;
  onDelete?: (index: number) => void;
  onFullScreen: (isFullScreen: boolean) => void;
}

const ImageCarousel = ({
  doorStore,
  currentIndex,
  onIndexChange,
  onDelete,
  onFullScreen,
}: Props) => (
  <CarouselStyles>
    <Carousel
      NavigatorButtonStyles={CarouselNavigatorButton}
      currentIndex={currentIndex}
      onIndexChange={onIndexChange}
    >
      {doorStore.photosUploadedByUser
        ?.map((photo, index) => (
          <ImageCarouselItem key={photo.url}>
            <DoorPhoto
              src={photo.url}
              index={index + 1}
              onDelete={onDelete && (() => onDelete(index))}
              onFullScreen={() => onFullScreen(true)}
            />
          </ImageCarouselItem>
        ))
        .concat(
          <ImageCarouselItem key={doorStore.photosUploadedByUser.length}>
            <NewImage doorStore={doorStore} />
          </ImageCarouselItem>
        )}
    </Carousel>
  </CarouselStyles>
);

export default ImageCarousel;
