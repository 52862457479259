import { Page, SubPage, Title } from '@adac/core-view';
import { __ } from '@adac/core-model';

export default () => (
  <Page>
    <SubPage>
      <Title>{__('SD-Partner assigned')}</Title>
    </SubPage>
  </Page>
);
