import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Field, FieldProps, useFormikContext } from 'formik';

import { CancellationReason, __ } from '@adac/core-model';
import { FlexView, RadioGroup, TextField, RadioButton } from '@adac/core-view';
import { CancellationFormValues } from './RejectCase';

const RadioButtonStyles = styled(RadioButton)`
  margin-right: 21px;
`;
interface IsOtherReasonProps {
  isOtherReasonTextFieldShown: boolean;
}
const TextFieldStyles = styled(TextField)<IsOtherReasonProps>`
  margin: 12px 0;
  margin-top: 16px;
  padding: 0 32px;
  ${(props) =>
    props.isOtherReasonTextFieldShown === true
      ? ' visibility: visible;'
      : 'visibility: hidden;'}
`;

interface Props {
  cancellationReasons: CancellationReason[];
  className?: string;
  children: React.ReactElement;
}

export default ({ cancellationReasons, className, children }: Props) => {
  const [isOtherReasonTextFieldShown, setOtherReasonTextFieldShown] =
    useState(false);
  const { values, setFieldValue } = useFormikContext<CancellationFormValues>();
  return (
    <Form>
      <FlexView column className={className} style={{ alignItems: 'left' }}>
        <RadioGroup
          renderItem={({ checked }) => <RadioButtonStyles checked={checked} />}
          withSelectedBacgkround
          items={cancellationReasons}
          value={values.reason}
          name='cancellationReason'
          cols={1}
          onChange={(value: string) => {
            setOtherReasonTextFieldShown(value === __('reason:Other'));
            setFieldValue('reason', value);
          }}
        />

        <Field
          name='detailedReason'
          render={({
            field,
          }: FieldProps<'detailedReason', CancellationFormValues>) => (
            <TextFieldStyles
              {...field}
              isOtherReasonTextFieldShown={isOtherReasonTextFieldShown}
              rows={3}
            />
          )}
        />
        {children}
      </FlexView>
    </Form>
  );
};
