import styled from 'styled-components';
import {
  InfoItem,
  Checkbox,
  Text,
  TextField,
  CheckboxItem,
  useView,
} from '@adac/core-view';
import { __, isValidMobilePhoneNumber } from '@adac/core-model';
import { observer } from 'mobx-react';
import { useIsInsuranceProduct } from '../../hooks/useIsInsuranceProduct';

const ReceiveDocumentsStyles = styled(InfoItem)`
  display: flex;
  flex-direction: column;
  text-align: left;
`;
const ReceiveDocumentsCheckbox = styled(Checkbox)`
  margin: 0;
  margin-top: 4px;
`;
interface ReceiveDocumentsProps {
  phoneNumber?: string;
  receiveByEmail: boolean;
  setReceiveByEmail: (prev: boolean) => void;
  onEmailChanged?: (email: string | null) => void;
  email?: string;
}

export const ReceiveDocuments = observer(
  ({
    phoneNumber,
    email,
    onEmailChanged,
    receiveByEmail,
    setReceiveByEmail,
  }: ReceiveDocumentsProps) => {
    const { isView } = useView();
    const isInsurance = useIsInsuranceProduct();

    const header = isInsurance
      ? __('receiveDocumentsNoPost')
      : __('Receive documents');

    return (
      <ReceiveDocumentsStyles header={header}>
        <>
          {phoneNumber && isValidMobilePhoneNumber(phoneNumber) && (
            <Text>{`${__('By SMS')} ${phoneNumber}`}</Text>
          )}
          <ReceiveDocumentsCheckbox
            id='receiveByEmail'
            labelTitle={__('By email')}
            checked={(isView && !!email) || receiveByEmail}
            value='receiveByEmail'
            onChange={() => {
              setReceiveByEmail(!receiveByEmail);
              if (onEmailChanged) {
                onEmailChanged(null);
              }
            }}
            render={CheckboxItem}
            disabled={isView}
          />
          {isView && email && <Text>{email}</Text>}
          {!isView && receiveByEmail && (
            <TextField
              onChange={(event: { target: { value: string | null } }) => {
                if (onEmailChanged) {
                  onEmailChanged(event.target.value);
                }
              }}
              defaultValue={email}
              badgeTitle='Email'
              badgeEqualsPlaceholder
            />
          )}
        </>
      </ReceiveDocumentsStyles>
    );
  }
);
