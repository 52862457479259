import { createContext } from 'react';
import {
  UIStore,
  themeConfig,
  CaseStore,
  searchCaseByTokenForPartner,
  DoorPhotoStore,
} from '@adac/core-view';

import { CipheredToken } from '@adac/core-model';

export interface Stores {
  ui: UIStore;
  case: CaseStore<CipheredToken>;
  beforeOpeningDoorPhotos: DoorPhotoStore;
  beforeExtraDamageStarting: DoorPhotoStore;
  afterOpeningDoorPhotos: DoorPhotoStore;
}

const uiStore = new UIStore(themeConfig);
const caseStore = new CaseStore<CipheredToken>(searchCaseByTokenForPartner);
const stores: Stores = {
  ui: uiStore,
  case: caseStore,
  beforeOpeningDoorPhotos: new DoorPhotoStore(),
  beforeExtraDamageStarting: new DoorPhotoStore(),
  afterOpeningDoorPhotos: new DoorPhotoStore(),
};

// Use the React context API so that the stores are available anywhere in the app
export default createContext(stores);
